<!-- eslint-disable vue/no-v-html -->
<template>
	<cart-totals
		:class="{ 'outline': isLastStep }"
		:loading="cartLoading"
		:totals="cartTotals"
		:small-text-totals="cartSmallTextTotals"
		:cart-items="cartItems"
		:total="cartTotal"
		:total-volume="totalVolume"
		:disable-action="!disclaimer || !isLastStep || !cartItems.length || productsLoading || customCartLoading || (showBackorderAgreement && !backorderAgreement)"
		:exchange="exchange"
		:discount-detail="discountDetail"
		action-classes="btn-lg"
		:action-text="selectableFreeProducts.length > 0 ? translate('choose_your_free_products') : ''"
		@action="handleFreeProductsPromotion">
		<div
			v-if="applyFreeBottle"
			class="row mt-2 mb-2">
			<div class="col d-flex justify-content-center">
				<div class="alert alert-green w-100 mb-0 pl-1">
					<ul class="fa-ul mb-0">
						<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate(freeBottleMessage)" /></li>
					</ul>
				</div>
			</div>
		</div>
		<div
			v-if="automaticDiscountPercentage > 0 && Object.keys(cartCoupon).length === 0"
			class="row mt-2 mb-2">
			<div class="col d-flex justify-content-center">
				<div class="alert alert-green w-100 mb-0 pl-1">
					<ul class="fa-ul mb-0">
						<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate('apply_automatic_discount_message', { percentage: automaticDiscountPercentage })" /></li>
					</ul>
				</div>
			</div>
		</div>
		<div
			v-if="[customer].includes(selectedPath)"
			class="row no-gutters mb-2">
			<div class="col">
				<coupon-input
					:cart-coupon="cartCoupon"
					:errors-import="couponErrors"
					:loading="couponLoading || removeCouponLoading || cartLoading"
					@submit="$emit('couponSubmit', $event, { sponsor_id: sponsor.id })"
					@remove="$emit('couponRemove')"
					@change="$emit('couponChange', $event)" />
			</div>
		</div>
		<div
			v-if="showSponsor || hasInitialSponsor"
			class="row">
			<div class="col">
				<sponsor-overview />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<disclaimer
					:checked="disclaimer"
					:terms="termsAndPolicies"
					@change="disclaimer = $event" />
			</div>
		</div>
		<div
			v-if="showBackorderAgreement"
			class="row">
			<div class="col">
				<backorder-agreement
					:checked="backorderAgreement"
					@change="backorderAgreement = $event" />
			</div>
		</div>
		<free-products-modal
			:sections-import="selectableFreeProducts"
			:selectable-products.sync="selectableProducts"
			:ok-title="translate('confirm')"
			:cancel-title="translate('go_back')"
			@close="showFreeProductsModal = false"
			@confirm="goSuccess" />
		<custom-cart-products-modal
			:cart-products="cartProducts"
			:cart-id="selectedCart"
			:loading="productsLoading"
			review-cart
			@close="showCustomCartProductsModal = false"
			@confirm="goSuccess(true)"
			@restore="restoreCart" />
	</cart-totals>
</template>
<script>
import CartTotals from '@/components/Cart/CartTotals';
import CouponInput from '@/components/Cart/CouponInput';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, INTERNAL_SERVER_ERROR,
} from '@/settings/Errors';
import { customer } from '@/settings/Roles';
import Disclaimer from './Disclaimer';
import SponsorOverview from './SponsorOverview';
import CommonMix from '../../mixins/Common';
import AgencySite from '@/mixins/AgencySite';
import store from '../../store';
import FreeProductsModal from '@/components/FreeProductsModal';
import CustomCartProductsModal from '@/components/CustomCartProductsModal';
import CustomCarts from '@/util/CustomCarts';
import EventBus from '@/util/eventBus';
import { PRE_CONFIRMATION_NOTE_COUNTRIES } from '@/settings/Country';
import BackorderAgreement from '@/components/BackorderAgreement/BackorderAgreement.vue';

export default {
	name: 'OrderSummary',
	components: {
		BackorderAgreement,
		CartTotals,
		CouponInput,
		Disclaimer,
		SponsorOverview,
		FreeProductsModal,
		CustomCartProductsModal,
	},
	mixins: [CommonMix, AgencySite],
	props: {
		freeProductsInfo: {
			type: Array,
			default: () => [],
		},
		cartLoading: {
			type: Boolean,
			default: false,
		},
		cartItems: {
			type: Array,
			default: () => [],
		},
		cartTotals: {
			type: Object,
			default: () => ({}),
		},
		cartSmallTextTotals: {
			type: Array,
			default: () => [],
		},
		cartTotal: {
			type: String,
			default: '',
		},
		couponErrors: {
			type: Object,
			default: () => ({}),
		},
		termsAndPolicies: {
			type: Object,
			default: () => ({}),
		},
		couponLoading: {
			type: Boolean,
			default: false,
		},
		removeCouponLoading: {
			type: Boolean,
			default: false,
		},
		cartCoupon: {
			type: Object,
			default: () => ({}),
		},
		totalVolume: {
			type: String,
			default: '',
		},
		exchange: {
			type: String,
			default: '',
		},
		showSponsor: {
			type: Boolean,
			default: false,
		},
		applyFreeBottle: {
			type: Boolean,
			default: false,
		},
		freeBottleMessage: {
			type: String,
			default: '',
		},
		automaticDiscountPercentage: {
			type: [Number, String],
			default: 0,
		},
		discountDetail: {
			type: Array,
			default: () => [],
		},
		isCustomerRegistration: {
			type: Boolean,
			default: false,
		},
		showBackorderAgreement: {
			type: Boolean,
			default: false,
		},
		// hasTickets: {
		// 	type: Boolean,
		// 	default: false,
		// },
		// ticketsInfo: {
		// 	type: Object,
		// 	default: () => ({}),
		// },
	},
	data() {
		return {
			alert: new this.$Alert(),
			customer,
			disclaimer: false,
			backorderAgreement: false,
			hasInitialSponsor: false,
			idempotencyId: '',
			paymentMethod: {
				name: '',
			},
			sponsor: {
				id: '',
				name: '',
			},
			showFreeProductsModal: false,
			selectableProducts: {},
			customCart: new CustomCarts(),
			selectedCart: '',
			showCustomCartProductsModal: false,
			cart: new CustomCarts(),
			cartRestore: new CustomCarts(),
			cartProducts: {},
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		selectedPath() {
			if (store.getters.getCompletedSteps().includes(0)) {
				return store.state.CheckoutPersonalInformation.path;
			}
			return false;
		},
		selectableFreeProducts() {
			try {
				return this.freeProductsInfo.filter((info) => info.show_modal).map((info) => info.selectable_products);
			} catch (e) {
				return [];
			}
		},
		productsLoading() {
			return !!this.cart.data.loading;
		},
		customCartLoading() {
			return !!this.customCart.data.loading;
		},
	},
	watch: {
		isLastStep() {
			if (this.isLastStep) {
				this.$emit('selected');
			}
		},
		currentValidation() {
			this.getStartingInfo();
		},
		showFreeProductsModal(show) {
			EventBus.$emit(show ? 'showFreeProductsModal' : 'hideFreeProductsModal');
		},
		showCustomCartProductsModal(show) {
			EventBus.$emit(show ? 'showCustomCartProductsModal' : 'hideCustomCartProductsModal');
		},
	},
	mounted() {
		this.getStartingInfo();
		this.refreshSponsorData();
	},
	methods: {
		getStartingInfo() {
			this.idempotencyId = this.createUuid();
			this.stepNames.forEach((stepName) => {
				if (stepName === 'CheckoutConfirmation') {
					return null;
				}

				const stepInfo = this.getStepInformation(stepName);
				if (stepName === 'CheckoutPersonalInformation') {
					const { sponsor, personal_information: personalInformation } = stepInfo;
					if (typeof personalInformation === 'undefined' || typeof sponsor === 'undefined') {
						return null;
					}

					this.path = stepInfo.path;

					if (sponsor.id !== this.sponsor.id) {
						this.refreshSponsorData();
					}
				} else if (stepName === 'CheckoutPayment') {
					const paymentMethod = { ...this.getStepInformation('CheckoutPayment').card_data };
					if (Object.keys(paymentMethod).length) {
						this.paymentMethod.name = stepInfo.payment.payment_method.name;
					}
				}

				return null;
			});
		},
		totalWithVClub() {
			const numericValue = parseFloat(this.cartTotal.replace(/[^0-9.-]+/g, ''));

			const vClubPrice = 9.95;
			const totalValue = numericValue + vClubPrice;

			return `$${totalValue.toFixed(2)} USD`;
		},
		handleFreeProductsPromotion() {
			if (this.selectableFreeProducts.length > 0) {
				this.showFreeProductsModal = true;
			} else {
				this.selectableProducts = {};
				this.goSuccess();
			}
		},
		goSuccess(confirmed = false) {
			// Validate cart products if it's a custom cart
			const replicatedPage = this.$replicated.replicatedPage();
			const customCartExists = localStorage.getItem('custom_cart_uuid') || false;
			const normalCustomCart = (replicatedPage === 'checkout') && customCartExists;
			if (normalCustomCart && !confirmed) {
				// When the replicated site is 'checkout', it means the user is using a custom cart
				// So, we'll get cart totals only if the cart id corresponds to a custom cart
				this.customCart.getCustomCart().then((response) => {
					this.formatProducts(response.id);
				});
			} else {
				this.goSuccessPreValidated();
			}
		},
		goSuccessPreValidated() {
			if (PRE_CONFIRMATION_NOTE_COUNTRIES.includes(this.country)) {
				const alertConfig = {
					icon: 'info',
					title: this.translate('note_title'),
					text: this.translate(`${this.country.toLowerCase()}_note_text`),
					showCancelButton: true,
					confirmButtonText: this.translate('confirm_note_i_agree'),
					reverseButtons: true,
				};
				this.alert.preConfirm(alertConfig, () => {
					this.goSuccessValidated();
				}, () => {});
			} else {
				this.goSuccessValidated();
			}
		},
		goSuccessValidated() {
			let payload = {
				selectable_products: this.selectableProducts,
			};

			if (this.agencyPath) {
				payload.ecommerce_path = this.agencyPath;
			}

			this.stepNames.forEach((stepName) => {
				if (stepName !== 'CheckoutConfirmation') {
					payload = { ...payload, ...this.getStepInformation(stepName) };
					if (stepName === 'CheckoutPersonalInformation') {
						payload.sponsor = this.sponsor.id;
					} else if (stepName === 'CheckoutPayment') {
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
					}
				}
			});

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};

			// if (this.hasTickets) {
			// 	payload.tickets = this.ticketsInfo;
			// }

			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			const validation = { ...payload, step: this.getStepValidationByName('CheckoutConfirmation') };
			this.validateStep(validation, true).then((response) => {
				this.removeInfo().then(() => {
					this.$emit('success');
					const {
						redirect_url: redirectUrl,
						verification_required: verificationRequired,
						crypto_wallet: cryptoWallet,
						order_id: orderId,
						order_total: orderTotal,
					} = response.response;
					// Redirect to instructed page
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}
					// Pass payment method to success page in case we need to display an alert for it
					const routeOptions = {
						name: this.getRedirectName('CheckoutSuccess'),
						query: {
							order_id: `${orderId}`,
							order_total: `${orderTotal}`,
							ecommerce_path: this.agencyPath,
						},
						// query: { payment_method: payload.payment.payment_method.name ?? '' },
					};
					// Show verbal verification alert
					if (verificationRequired) {
						routeOptions.query.verification_required = true;
					}
					// Go to crypto payment details
					if (cryptoWallet) {
						routeOptions.query = { wallet_address: cryptoWallet };
					}
					// Go to new route
					this.$router.replace(routeOptions);
					// Close 'Processing request...' alert
					this.alert.close();
				}).catch(() => {});
			}).catch((error) => {
				this.idempotencyId = this.createUuid();
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					let personalInfoErrors;

					Object.keys(error.errors).forEach((key) => {
						if (key.includes('personal_information')) {
							if (typeof personalInfoErrors === 'undefined') {
								personalInfoErrors = [];
							}
							const value = JSON.parse(JSON.stringify(error.errors[key]));
							personalInfoErrors = personalInfoErrors.concat(value);
						}
					});

					const {
						cart_id: cartId,
						payex,
						'payment.payment_method.name': paymentMethodName,
						'shipping.pickup_at_office': pickupAtOffice,
						sponsor,
						selectable_products: selectableProducts,
						/* , tickets, */
					} = error.errors;

					if (typeof cartId !== 'undefined') {
						if (typeof cartId === 'string' || typeof cartId === 'number') {
							this.$emit('cartReplaced', cartId, typeof payex === 'undefined');
						} else {
							let response = '';
							cartId.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
							setTimeout(() => {
								this.$emit('cartValidationError');
							}, 6000);
						}
					}
					if (typeof payex !== 'undefined') {
						let response = '';
						payex.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					}
					if (typeof paymentMethodName !== 'undefined') {
						let response = '';
						paymentMethodName.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
					}
					if (typeof selectableProducts !== 'undefined') {
						let response = '';
						selectableProducts.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('reloadCart');
						}, 6000);
					}
					if (typeof pickupAtOffice !== 'undefined') {
						let response = '';
						pickupAtOffice.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
					}
					// Redirect to the store if the sponsor was selected via the replicated site and gets a validation error
					if (!this.hasInitialSponsor && typeof sponsor !== 'undefined') {
						this.$emit('cantUseSelectedSponsor');
					}

					if (typeof personalInfoErrors !== 'undefined') {
						let response = '';
						personalInfoErrors.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					}
					// if (typeof tickets !== 'undefined') {
					// 	this.alert.toast('error', this.translate('event_attendees_validation'), { timer: 6000 });
					// }

					// If the validation error wasn't one of the above
					if ([cartId, payex, paymentMethodName/* , tickets */, personalInfoErrors, selectableProducts, pickupAtOffice].every((errorField) => typeof errorField === 'undefined')) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					}
				} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
					setTimeout(() => { this.$emit('serverError'); }, 2500);
				}
			});
			return null;
		},
		refreshSponsorData() {
			let sponsor = this.$replicated.replicatedSponsor();
			if (!sponsor) {
				sponsor = this.getStepInformation('CheckoutPersonalInformation').sponsor;
			} else {
				this.hasInitialSponsor = true;
			}

			if (sponsor) {
				this.getSponsorInfo(sponsor).then((response) => {
					// Redirect to the store if entering the flow with an invalid sponsor
					if (!response.can_sponsor_checkout && this.hasInitialSponsor) {
						this.$emit('cantUseSelectedSponsor');
					}
					Object.keys(this.sponsor).forEach((value) => {
						this.sponsor[value] = response[value];
					});
				});
			}
		},
		formatProducts(item) {
			this.selectedCart = item;
			this.cart.getCustomCartProducts(item).then(() => {
				this.cartProducts = this.cart.data.response.data.response;
				if (this.cartProducts.cart_changed) {
					this.showCustomCartProductsModal = true;
				} else {
					this.goSuccessPreValidated();
				}
			});
		},
		restoreCart() {
			this.showCustomCartProductsModal = false;
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			this.cartRestore.restoreCustomCart(localStorage.getItem('custom_cart_uuid')).then(() => {
				this.alert.close();
				this.$emit('reloadCart');
			});
		},
	},
};
</script>
<style scoped>
.outline {
	outline: 0;
	border-radius: 0.3rem;
	-webkit-box-shadow: 0 0 0 0.2rem #eb772f40;
	box-shadow: 0 0 0 0.2rem #eb772f40; /* eb772f40 */
}
</style>
